<template>
  <div class="view-home">
    <div class="container">
      <div class="quiz-status my-4">
        <div class="card" v-show="countdown">
          <ul>
            <li class="time">
              {{ countdown }}
            </li>
            <li class="recording" v-if="quiz.proctoring">
              {{ $t('testing.recording') }}
            </li>
            <li class="interruption" v-if="quiz.proctoring">
              {{ $t('testing.interruption') }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container questions-wrap">
      <h2>
        {{ $t('creativity.title') }}
      </h2>
      <h3>
        {{ $t('creativity.subtitle') }}
      </h3>
      <div class="collaboration-desc" v-html="$t('creativity.instruction') ">

      </div>

<!--      <div v-for="(question, index) in questionsCreativity" :key="index" class="question-radio">-->
<!--        {{ question.text }} <br>-->
<!--        <el-radio v-model="answersCreativity[index].choice" label="-">{{ $t('creativity.no') }}</el-radio>-->
<!--        <el-radio v-model="answersCreativity[index].choice" label="+">{{ $t('creativity.yes') }}</el-radio>-->
<!--      </div>-->

      <div class="d-flex flex-column">
        <div class="question-radio" :style="{ order: orderQuestionsCreativity[0] }">
          {{orderQuestionsCreativity[0]}}{{ $t('creativity.question-1') }} <br>
          <el-radio v-model="answersCreativity[0].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[0].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[1] }">
          {{orderQuestionsCreativity[1]}}{{ $t('creativity.question-2') }} <br>
          <el-radio v-model="answersCreativity[1].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[1].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[2] }">
          {{orderQuestionsCreativity[2]}}{{ $t('creativity.question-3') }} <br>
          <el-radio v-model="answersCreativity[2].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[2].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[3] }">
          {{orderQuestionsCreativity[3]}}{{ $t('creativity.question-4') }} <br>
          <el-radio v-model="answersCreativity[3].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[3].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[4] }">
          {{orderQuestionsCreativity[4]}}{{ $t('creativity.question-5') }} <br>
          <el-radio v-model="answersCreativity[4].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[4].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[5] }">
          {{orderQuestionsCreativity[5]}}{{ $t('creativity.question-6') }} <br>
          <el-radio v-model="answersCreativity[5].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[5].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[6] }">
          {{orderQuestionsCreativity[6]}}{{ $t('creativity.question-7') }} <br>
          <el-radio v-model="answersCreativity[6].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[6].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[7] }">
          {{orderQuestionsCreativity[7]}}{{ $t('creativity.question-8') }} <br>
          <el-radio v-model="answersCreativity[7].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[7].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[8] }">
          {{orderQuestionsCreativity[8]}}{{ $t('creativity.question-9') }} <br>
          <el-radio v-model="answersCreativity[8].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[8].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[9] }">
          {{orderQuestionsCreativity[9]}}{{ $t('creativity.question-10') }} <br>
          <el-radio v-model="answersCreativity[9].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[9].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[10] }">
          {{orderQuestionsCreativity[10]}}{{ $t('creativity.question-11') }} <br>
          <el-radio v-model="answersCreativity[10].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[10].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[11] }">
          {{orderQuestionsCreativity[11]}}{{ $t('creativity.question-12') }} <br>
          <el-radio v-model="answersCreativity[11].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[11].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[12] }">
          {{orderQuestionsCreativity[12]}}{{ $t('creativity.question-13') }} <br>
          <el-radio v-model="answersCreativity[12].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[12].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[13] }">
          {{orderQuestionsCreativity[13]}}{{ $t('creativity.question-14') }} <br>
          <el-radio v-model="answersCreativity[13].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[13].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[14] }">
          {{orderQuestionsCreativity[14]}}{{ $t('creativity.question-15') }} <br>
          <el-radio v-model="answersCreativity[14].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[14].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[15] }">
          {{orderQuestionsCreativity[15]}}{{ $t('creativity.question-16') }} <br>
          <el-radio v-model="answersCreativity[15].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[15].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[16] }">
          {{orderQuestionsCreativity[16]}}{{ $t('creativity.question-17') }} <br>
          <el-radio v-model="answersCreativity[16].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[16].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[17] }">
          {{orderQuestionsCreativity[17]}}{{ $t('creativity.question-18') }} <br>
          <el-radio v-model="answersCreativity[17].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[17].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[18] }">
          {{orderQuestionsCreativity[18]}}{{ $t('creativity.question-19') }} <br>
          <el-radio v-model="answersCreativity[18].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[18].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[19] }">
          {{orderQuestionsCreativity[19]}}{{ $t('creativity.question-20') }} <br>
          <el-radio v-model="answersCreativity[19].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[19].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[20] }">
          {{orderQuestionsCreativity[20]}}{{ $t('creativity.question-21') }} <br>
          <el-radio v-model="answersCreativity[20].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[20].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[21] }">
          {{orderQuestionsCreativity[21]}}{{ $t('creativity.question-22') }} <br>
          <el-radio v-model="answersCreativity[21].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[21].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[22] }">
          {{orderQuestionsCreativity[22]}}{{ $t('creativity.question-23') }} <br>
          <el-radio v-model="answersCreativity[22].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[22].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[23] }">
          {{orderQuestionsCreativity[23]}}{{ $t('creativity.question-24') }} <br>
          <el-radio v-model="answersCreativity[23].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[23].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[24] }">
          {{orderQuestionsCreativity[24]}}{{ $t('creativity.question-25') }} <br>
          <el-radio v-model="answersCreativity[24].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[24].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[25] }">
          {{orderQuestionsCreativity[25]}}{{ $t('creativity.question-26') }} <br>
          <el-radio v-model="answersCreativity[25].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[25].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[26] }">
          {{orderQuestionsCreativity[26]}}{{ $t('creativity.question-27') }} <br>
          <el-radio v-model="answersCreativity[26].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[26].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[27] }">
          {{orderQuestionsCreativity[27]}}{{ $t('creativity.question-28') }} <br>
          <el-radio v-model="answersCreativity[27].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[27].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[28] }">
          {{orderQuestionsCreativity[28]}}{{ $t('creativity.question-29') }} <br>
          <el-radio v-model="answersCreativity[28].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[28].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[29] }">
          {{orderQuestionsCreativity[29]}}{{ $t('creativity.question-30') }} <br>
          <el-radio v-model="answersCreativity[29].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[29].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[30] }">
          {{orderQuestionsCreativity[30]}}{{ $t('creativity.question-31') }} <br>
          <el-radio v-model="answersCreativity[30].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[30].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[31] }">
          {{orderQuestionsCreativity[31]}}{{ $t('creativity.question-32') }} <br>
          <el-radio v-model="answersCreativity[31].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[31].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[32] }">
          {{orderQuestionsCreativity[32]}}{{ $t('creativity.question-33') }} <br>
          <el-radio v-model="answersCreativity[32].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[32].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[33] }">
          {{orderQuestionsCreativity[33]}}{{ $t('creativity.question-34') }} <br>
          <el-radio v-model="answersCreativity[33].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[33].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[34] }">
          {{orderQuestionsCreativity[34]}}{{ $t('creativity.question-35') }} <br>
          <el-radio v-model="answersCreativity[34].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[34].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[35] }">
          {{orderQuestionsCreativity[35]}}{{ $t('creativity.question-36') }} <br>
          <el-radio v-model="answersCreativity[35].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[35].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[36] }">
          {{orderQuestionsCreativity[36]}}{{ $t('creativity.question-37') }} <br>
          <el-radio v-model="answersCreativity[36].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[36].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[37] }">
          {{orderQuestionsCreativity[37]}}{{ $t('creativity.question-38') }} <br>
          <el-radio v-model="answersCreativity[37].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[37].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[38] }">
          {{orderQuestionsCreativity[38]}}{{ $t('creativity.question-39') }} <br>
          <el-radio v-model="answersCreativity[38].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[38].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>

        <div class="question-radio" :style="{ order: orderQuestionsCreativity[39] }">
          {{orderQuestionsCreativity[39]}}{{ $t('creativity.question-40') }} <br>
          <el-radio v-model="answersCreativity[39].choice" label="-">{{ $t('creativity.no') }}</el-radio>
          <el-radio v-model="answersCreativity[39].choice" label="+">{{ $t('creativity.yes') }}</el-radio>
        </div>
      </div>


      <!--      <button @click="sendCollaborationResults">Отправить</button>-->
      <el-button @click="openModal"
                 class="btn btn-danger">{{ $t('testing.finish-modal-button') }}
      </el-button>

    </div>
    <b-modal id="exit-modal" hide-footer hide-header>
      <div id="modal-wrapper">
        <div id="dialog">
          <button @click="$bvModal.hide('exit-modal')" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <img class="modal-warning-img mb-3" src="/images/profile/modal-warning.svg">
          <h3 class="mb-0">{{modalTitle}}</h3>
          <p>{{ $t('testing.finish-modal-desc') }}</p>
          <div class="clearfix pt-3">
            <el-button @click="sendCreativityResults" class="btn btn-primary mx-2">
              {{ $t('testing.finish-modal-button') }}
            </el-button>
            <a @click="$bvModal.hide('exit-modal')"
               class="btn btn-outline-primary mx-2">{{ $t('testing.finish-modal-cancel') }}</a>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import 'katex/dist/katex.min.css';
import Loader from '../../components/Loader';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue';

Vue.use(VueViewer)
export default {
  name: "Index",
  data() {
    return {
      modalTitle: this.$t('testing.finish-modal-title'),
      timerInterval: null,
      countdown: 0,
      quiz: '',
      questionsCreativity: [
        {
          numberQuestion: 1,
          text: this.$t('creativity.question-1')
        },
        {
          numberQuestion: 2,
          text: this.$t('creativity.question-2')
        },
        {
          numberQuestion: 3,
          text: this.$t('creativity.question-3')
        },
        {
          numberQuestion: 4,
          text: this.$t('creativity.question-4')
        },
        {
          numberQuestion: 5,
          text: this.$t('creativity.question-5')
        },
        {
          numberQuestion: 6,
          text: this.$t('creativity.question-6')
        },
        {
          numberQuestion: 7,
          text: this.$t('creativity.question-7')
        },
        {
          numberQuestion: 8,
          text: this.$t('creativity.question-8')
        },
        {
          numberQuestion: 9,
          text: this.$t('creativity.question-9')
        },
        {
          numberQuestion: 10,
          text: this.$t('creativity.question-10')
        },
        {
          numberQuestion: 11,
          text: this.$t('creativity.question-11')
        },
        {
          numberQuestion: 12,
          text: this.$t('creativity.question-12')
        },
        {
          numberQuestion: 13,
          text: this.$t('creativity.question-13')
        },
        {
          numberQuestion: 14,
          text: this.$t('creativity.question-14')
        },
        {
          numberQuestion: 15,
          text: this.$t('creativity.question-15')
        },
        {
          numberQuestion: 16,
          text: this.$t('creativity.question-16')
        },
        {
          numberQuestion: 17,
          text: this.$t('creativity.question-17')
        },
        {
          numberQuestion: 18,
          text: this.$t('creativity.question-18')
        },
        {
          numberQuestion: 19,
          text: this.$t('creativity.question-19')
        },
        {
          numberQuestion: 20,
          text: this.$t('creativity.question-20')
        },
        {
          numberQuestion: 21,
          text: this.$t('creativity.question-21')
        },
        {
          numberQuestion: 22,
          text: this.$t('creativity.question-22')
        },
        {
          numberQuestion: 23,
          text: this.$t('creativity.question-23')
        },
        {
          numberQuestion: 24,
          text: this.$t('creativity.question-24')
        },
        {
          numberQuestion: 25,
          text: this.$t('creativity.question-25')
        },
        {
          numberQuestion: 26,
          text: this.$t('creativity.question-26')
        },
        {
          numberQuestion: 27,
          text: this.$t('creativity.question-27')
        },
        {
          numberQuestion: 28,
          text: this.$t('creativity.question-28')
        },
        {
          numberQuestion: 29,
          text: this.$t('creativity.question-29')
        },
        {
          numberQuestion: 30,
          text: this.$t('creativity.question-30')
        },
        {
          numberQuestion: 31,
          text: this.$t('creativity.question-31')
        },
        {
          numberQuestion: 32,
          text: this.$t('creativity.question-32')
        },
        {
          numberQuestion: 33,
          text: this.$t('creativity.question-33')
        },
        {
          numberQuestion: 34,
          text: this.$t('creativity.question-34')
        },
        {
          numberQuestion: 35,
          text: this.$t('creativity.question-35')
        },
        {
          numberQuestion: 36,
          text: this.$t('creativity.question-36')
        },
        {
          numberQuestion: 37,
          text: this.$t('creativity.question-37')
        },
        {
          numberQuestion: 38,
          text: this.$t('creativity.question-38')
        },
        {
          numberQuestion: 39,
          text: this.$t('creativity.question-39')
        },
        {
          numberQuestion: 40,
          text: this.$t('creativity.question-40')
        }
      ],
      // answersCreativity: [
      //   { choice: "-", question: "1" },
      //   { choice: "+", question: "2" },
      //   { choice: "-", question: "3" },
      //   { choice: "+", question: "4" },
      //   { choice: "-", question: "5" },
      //   { choice: "+", question: "6" },
      //   { choice: "-", question: "7" },
      //   { choice: "+", question: "8" },
      //   { choice: "-", question: "9" },
      //   { choice: "+", question: "10" },
      //   { choice: "-", question: "11" },
      //   { choice: "+", question: "12" },
      //   { choice: "-", question: "13" },
      //   { choice: "-", question: "14" },
      //   { choice: "+", question: "15" },
      //   { choice: "-", question: "16" },
      //   { choice: "-", question: "17" },
      //   { choice: "+", question: "18" },
      //   { choice: "-", question: "19" },
      //   { choice: "+", question: "20" },
      //   { choice: "-", question: "21" },
      //   { choice: "+", question: "22" },
      //   { choice: "-", question: "23" },
      //   { choice: "+", question: "24" },
      //   { choice: "-", question: "25" },
      //   { choice: "+", question: "26" },
      //   { choice: "-", question: "27" },
      //   { choice: "-", question: "28" },
      //   { choice: "-", question: "29" },
      //   { choice: "+", question: "30" },
      //   { choice: "-", question: "31" },
      //   { choice: "-", question: "32" },
      //   { choice: "+", question: "33" },
      //   { choice: "-", question: "34" },
      //   { choice: "-", question: "35" },
      //   { choice: "-", question: "36" },
      //   { choice: "-", question: "37" },
      //   { choice: "-", question: "38" },
      //   { choice: "-", question: "39" },
      //   { choice: "-", question: "40" }
      // ],
      answersCreativity: [
        { choice: "", question: "1" },
        { choice: "", question: "2" },
        { choice: "", question: "3" },
        { choice: "", question: "4" },
        { choice: "", question: "5" },
        { choice: "", question: "6" },
        { choice: "", question: "7" },
        { choice: "", question: "8" },
        { choice: "", question: "9" },
        { choice: "", question: "10" },
        { choice: "", question: "11" },
        { choice: "", question: "12" },
        { choice: "", question: "13" },
        { choice: "", question: "14" },
        { choice: "", question: "15" },
        { choice: "", question: "16" },
        { choice: "", question: "17" },
        { choice: "", question: "18" },
        { choice: "", question: "19" },
        { choice: "", question: "20" },
        { choice: "", question: "21" },
        { choice: "", question: "22" },
        { choice: "", question: "23" },
        { choice: "", question: "24" },
        { choice: "", question: "25" },
        { choice: "", question: "26" },
        { choice: "", question: "27" },
        { choice: "", question: "28" },
        { choice: "", question: "29" },
        { choice: "", question: "30" },
        { choice: "", question: "31" },
        { choice: "", question: "32" },
        { choice: "", question: "33" },
        { choice: "", question: "34" },
        { choice: "", question: "35" },
        { choice: "", question: "36" },
        { choice: "", question: "37" },
        { choice: "", question: "38" },
        { choice: "", question: "39" },
        { choice: "", question: "40" },
      ],
      resultsCreativity: [],
      trainingProfile: {},
      orderQuestionsCreativity: [],
    }
  },
  methods: {
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
    openModal() {
      this.$bvModal.show('exit-modal');
    },
    determineLevel(count, lang) {
      if (count >= 6) {
        return lang == "ru" ? "высокий" : "жоғары деңгей";
      } else if (count >= 3) {
        return lang == "ru" ? "средний" : "орташа деңгей";
      } else {
        return lang == "ru" ? "в зоне ближайшего развития" : "жақын даму аймағында";
      }
    },
    async sendCreativityResults() {

      const thinking = {
        PD: 0,
        AS: 0,
        SL: 0,
        NO: 0,
        K: 0
      };

      for (let i = 0; i < 40; i += 5) {
        thinking.PD += this.answersCreativity[i].choice === '+' ? 1 : 0;
        thinking.AS += this.answersCreativity[i + 1].choice === '+' ? 1 : 0;
        thinking.SL += this.answersCreativity[i + 2].choice === '+' ? 1 : 0;
        thinking.NO += this.answersCreativity[i + 3].choice === '+' ? 1 : 0;
        thinking.K += this.answersCreativity[i + 4].choice === '+' ? 1 : 0;
      }

      const resultsCreativity = Object.entries(thinking).map(([title, countPlus]) => ({
        title,
        countPlus
      }));

      // const maxCount = Math.max(...resultsCreativity.map(obj => obj.countPlus));
      this.resultsCreativity = resultsCreativity.sort( (a, b) => b.countPlus - a.countPlus);

      if ( this.resultsCreativity[0].title == 'PD' && this.resultsCreativity[0].countPlus != this.resultsCreativity[1].countPlus) {
        this.trainingProfile.ru = "Оборонно-спортивный"
        this.trainingProfile.kz = "Қорғаныс спорты"

      } else if ( (this.resultsCreativity[0].title + this.resultsCreativity[1].title) == 'ASPD') {
        this.trainingProfile.ru = "Физико-математический, Естественно-научный (физика и химия), Естественно-научный (биология и географии)"
        this.trainingProfile.kz = "Физикалық-математикалық, Ғылыми-жаралыстану   (физика және химия), Ғылыми-жаралыстану (биология және география)"

      } else if ( (this.resultsCreativity[0].title + this.resultsCreativity[1].title) == 'ASSL') {
        this.trainingProfile.ru = "Социально-экономический, Информационно-технологический"
        this.trainingProfile.kz = "Әлеуметтік-экономикалық, Ақпараттық-технологиялық"

      } else if ( (this.resultsCreativity[0].title + this.resultsCreativity[1].title) == 'SLPD') {
        this.trainingProfile.ru = "Гуманитарный"
        this.trainingProfile.kz = "Гуманитарлық"

      } else if ( (this.resultsCreativity[0].title + this.resultsCreativity[1].title) == 'SLNO') {
        this.trainingProfile.ru = "Филологический"
        this.trainingProfile.kz = "Филологиялық"

      } else if ( (this.resultsCreativity[0].title + this.resultsCreativity[1].title) == 'PDAS') {
        this.trainingProfile.ru = "Агро-технологический"
        this.trainingProfile.kz = "Агротехнологиялық"

      } else if ( (this.resultsCreativity[0].title + this.resultsCreativity[1].title) == 'PDNO') {
        this.trainingProfile.ru = "Индустриально-технологический"
        this.trainingProfile.kz = "Индустриальды-технологиялық"

      } else if ( (this.resultsCreativity[0].title + this.resultsCreativity[1].title) == 'NOPD') {
        this.trainingProfile.ru = "Художественно-эстетический"
        this.trainingProfile.kz = "Көркемдік-эстетикалық"

      } else {
        this.trainingProfile.ru = "нет рекомендации"
        this.trainingProfile.kz = "ұсыныс жоқ"
      }

      this.resultsCreativity = this.resultsCreativity.map( item => {
        return {...item, text_ru: this.interpretationText(item.title, "ru"), text_kz: this.interpretationText(item.title, "kz")}
      })

      let textsInterpretation = {};

      textsInterpretation.ru = this.resultsCreativity.map(item => item.text_ru + ` Уровень развития данного типа мышления: ${this.determineLevel(item.countPlus, "ru")}. <br>`).join("\n") + "\n" + `Ваш тип мышления: ${this.trainingProfile.ru}.`;

      textsInterpretation.kz = this.resultsCreativity.map(item => item.text_kz + ` Ойлаудың осы түрінің даму деңгейі: ${this.determineLevel(item.countPlus, "kz")}. <br>`).join("\n") + "\n" + `Сіздің ойлау түріңіз: ${this.trainingProfile.kz}.`;

      textsInterpretation.ru = textsInterpretation.ru + "<br> В чистом виде эти типы мышления встречаются редко. Для многих профессий необходимо сочетание разных типов мышления, например, для психолога. Такое мышление называют синтетическим. В любой профессии также важна креативность. Соотнесите свой ведущий тип мышления с выбранным видом деятельности или профилем обучения. Ярко выраженный тип мышления дает некоторые преимущества в освоении соответствующих видов деятельности. Но важнее всего ваши способности и интерес к будущей профессии. Насколько удачен ваш выбор? Если ваши профессиональные планы не вполне соответствуют типу мышления, подумайте, что легче изменить – планы или тип мышления?"
      textsInterpretation.kz = textsInterpretation.kz + "<br> Таза түрінде ойлаудың бұл түрлері сирек кездеседі. Көптеген кәсіптер үшін психолог сияқты әртүрлі ойлау түрлерінің үйлесімі қажет. Бұл ойлау синтетикалық деп аталады. Креативтілік кез келген кәсіпте маңызы зор. Өзіңіздің жетекші ойлау түріңізді таңдалған әрекетпен немесе оқу профилімен байланыстырыңыз. Ойлаудың айқын түрі тиісті іс-әрекеттерді игеруде кейбір артықшылықтар береді. Бірақ сіздің қабілеттеріңіз бен болашақ мамандыққа деген қызығушылығыңыз маңызды. Сіздің таңдауыңыз қаншалықты сәтті? Егер сіздің кәсіби жоспарларыңыз ойлау түріне сәйкес келмесе, нені өзгерту оңайырақ деп ойлайсыз – жоспарлар немесе ойлау түрі?"

      if (this.resultsCreativity.filter(item => item.title == "K")[0].countPlus <= 5) {
        textsInterpretation.ru = textsInterpretation.ru + "<br> Креативность, одна из главных способностей признанных в мире. Она может быть развита у любого человека. Она позволяет находить новые решения для жизни. Имеет много преимуществ. <br> Как развить творческое, или креативное мышление? Использовать следующие проверенные техники:<br> * менять обстановку, выходить за рамки;<br> * переключаться между задачами, а не сидеть над чем-то одним;<br> * придумывать идеи самостоятельно, а обсуждать их на общем мозговом штурме;<br> * слушать счастливую музыку — исследования утверждают, что она улучшает креативное мышление;<br> * развивать чувство юмора и позитивное отношение к жизни;<br> * воспроизводить чужие идеи и создавать на их основе свои."
        textsInterpretation.kz = textsInterpretation.kz + "<br> Шығармашылық әлемде ең негізгі болып танылған қабілеттердің бірі. Ол кез-келген адамда дамуы мүмкін. Бұл өмірде жаңа шешімдер табуға мүмкіндік беретін қасиет. Оның көптеген артықшылықтары бар. <br> Шығармашылық немесе креативті ойлауды қалай дамытуға болады? Келесі дәлелденген әдістерді қолданыңыз: <br> * өлшемдерді өзгерт, шектеуден шығыңыз;<br> * бір нәрсемен ғана айналыспай, әртүрлі тапсырмаларға ауысып отырған дұрыс;<br> * идеяларды өзіңіз ойлап тауып, оларды жалпы ми шабуылында талқылаңыз;<br> * көңілді музыкаларды тыңдаңыз, зерттеулер оның шығармашылық ойлауды жақсартатынын айтады;<br> * әзіл сезімін және өмірге деген оң көзқарасты дамыту;<br> * басқа адамдардың идеяларын қайта қарай отырып, олардың негізінде өз идеяларыңызды жасаңыз."
      }


      // textsInterpretation = textsInterpretation + "\n" + `Ваш тип мышления: ${this.trainingProfile}.`

      console.log('texts', textsInterpretation)

      let params = JSON.parse(localStorage.getItem('quiz-params'))

      const creativitySendAnswers = [
        this.answersCreativity, {resultsCreativity: this.resultsCreativity}, {trainingProfile: this.trainingProfile}, {textsInterpretation}
      ]

      params.answer_id = {...params.answer_id, creativitySendAnswers}

      localStorage.setItem('quiz-params', JSON.stringify(params))
      const quizToken = localStorage.getItem('quiz-token')

      const response = await this.$http.post(API_ROOT + '/api/user-answer/' + quizToken, params)

      if (response.body.status == 'success') {

        Vue.toastr({
          message: this.$t('successful'),
          description: this.$t('saved-successfully'),
          type: 'success'
        })
      } else {
        Vue.toastr({
          message: this.$t('error-title'),
          description: this.$t('error-saving'),
          type: 'error'
        })
      }


      console.log('this.resultsCreativity', this.resultsCreativity)
      console.log('this.trainingProfile', this.trainingProfile)
      console.log('this.resultsCreativity', this.resultsCreativity)
      console.log('this.params', params)

      setTimeout(() => {
        this.$router.push({ path: '/about-new/communication' })
      }, 1000)

    },
    startTimer(duration) {
      let timer = duration, minutes, seconds;
      this.timerInterval = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.countdown = minutes + ":" + seconds;

        let savedTimer = (parseInt(minutes) * 60) + parseInt(seconds);
        localStorage.setItem('timerСreativity', savedTimer);

        if (--timer < 0) {
          this.sendCreativityResults()
          clearInterval(this.timerInterval);
          // this.saveAnswer();
          // this.exit();
          // timer = duration;
        }
      }, 1000);
    },
    interpretationText(str, lang) {
      if (str == "K") {
        // return this.$t('creativity.interpretation-K')
        return lang == "ru" ? "Креативность (К) – это способность мыслить творчески, находить нестандартные решения задачи. Это редкое и ничем не заменимое качество, отличающее людей, талантливых в любой сфере деятельности." : "Креативтілік (К) бұл шығармашылықпен ойлау, мәселенің стандартты емес шешімдерін табу қабілеті. Бұл кез-келген іс-әрекет саласында талантты адамдарды ерекшелейтін сирек кездесетін және алмастырылмайтын қасиет."
      } else if (str == "PD") {
        // return this.$t('creativity.interpretation-PD')
        return lang == "ru" ? "Предметно-действенное мышление (П-Д) свойственно людям дела. Они усваивают информацию через движения. Обычно они обладают хорошей координацией движений. Их руками создан весь окружающий нас предметный мир. Они водят машины, стоят у станков, собирают компьютеры. Без них невозможно реализовать самую блестящую идею. Этим мышление важно для спортсменов, танцоров, артистов."
            : "Пәндік-әрекеттік ойлау (П-Ә), бұл ойлау түрі іс-әрекет адамдарына тән. Олар ақпаратты қозғалыс арқылы игереді. Олар әдетте жақсы қозғалыс координациясына ие. Олардың қолымен бізді қоршаған бүкіл әлем құрылды. Олар көлік жүргізеді, станок жанында тұрады, компьютерлер құрастырады. Оларсыз ең керемет идеяны жүзеге асыру мүмкін емес. Бұл ойлау түрі спортшылар, бишілер, суретшілер үшін маңызды."
      } else if (str == "AS") {
        // return this.$t('creativity.interpretation-AS')
        return lang == "ru" ? "Абстрактно-символическим мышлением (А-С) обладают многие ученые – физики-теоретики, математики, экономисты, программисты, аналитики. Они могут усваивать информацию с помощью математических кодов, формул и операций, которые нельзя ни потрогать, ни представить. Благодаря особенностям такого мышления на основе гипотез сделаны многие открытия во всех областях науки."
            : "Абстрактілі-символдық ойлау (А-С) бұл ойлау түрі көптеген ғалымдарға тән – физик-теоретиктер, математиктер, экономистер, бағдарламашылар, аналитиктер. Олар ақпаратты математикалық кодтар, формулалар және қол тигізбейтін немесе ұсынылмайтын амалдар арқылы игере алады. Осындай ойлаудың ерекшеліктеріне байланысты ғылымның барлық салаларында гипотезалар негізінде көптеген жаңалықтар ашылды."
      } else if (str == "SL") {
        // return this.$t('creativity.interpretation-SL')
        return lang == "ru" ? "Словесно-логическое мышление (С-Л) отличает людей с ярко выраженным вербальным интеллектом (от лат. verbalis – словесный). Благодаря развитому словесно-логическому мышлению ученый, преподаватель, переводчик, писатель, филолог, журналист могут сформулировать свои мысли и донести их до людей. Это умение необходимо руководителям, политикам и общественным деятелям."
            : "Сөздік-логикалық ойлау (С-Л) бұл ойлау түрі  сөйлеу интеллектісі бар адамдарға тән (латын тілінен, «вербалис» - ауызша). Дамыған сөздік-логикалық ойлаудың нәтижесінде, ғалым, оқытушы, аудармашы, жазушы, филолог, журналист өз ойларын тұжырымдап, адамдарға жеткізе алады. Бұл дағды басшыларға, саясаткерлерге және қоғам қайраткерлеріне қажет."
      } else {
        return lang == "ru" ? "Наглядно-образным мышлением (Н-О) обладают люди с художественным складом ума, которые могут представить и то, что было, и то, что будет, и то, чего никогда не было и не будет – художники, поэты, писатели, режиссеры. Архитектор, конструктор, дизайнер, художник, режиссер должны обладать развитым наглядно-образным мышлением."
            : "Көрнекі-бейнелі ойлау (К–Б) бұл ойлау түрлі көркемдік ойлау қабілеті бар адамдарға тән, олар не болғанын, не болатынын және ешқашан болмаған және болмайтын нәрсені елестете алады, оларға суретшілерді, ақындарды, жазушыларды, режиссерлерді жатқызуға болады. Сәулетші, дизайнер, суретші, режиссер – олар  көрнекі-бейнелі ойлауды дамытуы керек."
      }
    }
  },
  mounted() {
    if (localStorage.getItem('timerСreativity')) {
      this.startTimer(localStorage.getItem('timerСreativity'))
    } else {
      this.startTimer(300)
    }

    let test_lang = localStorage.getItem('test_lang')

    const array = [];
    for (let i = 1; i <= 40; i++) {
      array.push(i);
    }

    this.orderQuestionsCreativity = this.shuffleArray(array)

    if (test_lang){
      this.$i18n.locale = test_lang;
    }
  }
}
</script>

<style>
.select-custom-collaboration {
  width: 240px !important;
}

.collaboration-desc {
  margin: 20px 0;
}

.collaboration-options {
  cursor: pointer;
  position: relative;
}

.el-select-group__title {
  position: relative;
}

.el-select-group__title::after {
  position: absolute;
  right: 10px;
  top: 2px;
  content: '▼';
}


</style>
